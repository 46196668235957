import classNames from 'classnames';
import React from 'react';

import css from './MembershipPlans.module.css';

function MembershipPlans() {
  return (
    <div className={css.mainContainer}>
      <h4 className={css.pricing}>Activity Time business pricing</h4>
      <h3>Choose your plan & Start your 90 day FREE trial</h3>

      <h4>Built for where you are and where you want to go.</h4>
      <div className={css.plansCardContainer}>
        <div className={css.plansCardContent}>
          <div className={classNames(css.plansCard, css.newbiePlansCard)}>
            <h4>Starter</h4>
            <span className={css.planType}>€2/booking</span>
            <span className={css.monthlyPlanType}>only on completed bookings</span>
            <button type="button" className={css.getStartBtn}>
              Start my free trial
            </button>
            <span className={css.planContent}>
              <br />
              No set-up fees required <br /> <br />
              For activity businesses who want to improve sales and growth. Only pay us when you get
              paid first.
              <br /> <br />3 categories available
              <br />
              <br />
              <center>
                <b>
                  <u>Best Bit:</u>
                </b>
              </center>
            </span>
          </div>

          <div className={classNames(css.plansCard, css.growthPlansCard)}>
            <h4>Build</h4>
            <span className={css.planType}>€15/month</span>
            <span className={css.monthlyPlanType}>billed monthly</span>

            <button type="button" className={css.getStartBtn}>
              Start my free trial
            </button>
            <span className={css.planContent}>
              <br />
              Can start/stop as needed <br /> <br />
              For activity businesses who want to control expenses monthly. All features included.
              <br /> <br />
              All 11 categories available
              <br />
              <br />
              <center>
                <b>
                  <u>Best Bit:</u>
                </b>
              </center>
            </span>
          </div>
        </div>

        <div className={css.plansCardContent}>
          <div className={classNames(css.plansCard, css.proPlansCard)}>
            <h4>Grow</h4>
            <span className={css.planType}>€150/yr</span>
            <span className={css.monthlyPlanType}>billed annually</span>
            <button type="button" className={css.getStartBtn}>
              Start my free trial
            </button>
            <span className={css.planContent}>
              <br />
              Cancel anytime <br /> <br />
              For full-time activity businesses who want the best value. All features included.
              <br />
              <br />
              All 11 categories available
              <br />
              <br />
              <center>
                <b>
                  <u>Best Bit:</u>
                </b>
              </center>
            </span>
          </div>

          <div className={classNames(css.plansCard, css.enterprisePlansCard)}>
            <h4>Enterprise</h4>
            <span className={css.planType}>Custom pricing</span>
            <span className={css.monthlyPlanType}>Contact our team</span>
            <button type="button" className={css.getStartBtn}>
              Start my free trial
            </button>
            <span className={css.planContent}>
              <br />
              Cancel anytime <br /> <br />
              For enterprise-level businesses and franchises. Customize with the right tools for
              your business.
              <br /> <br />
              All 11 categories available
              <br />
              <br />
              <center>
                <b>
                  <u>Best Bit:</u>
                </b>
              </center>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MembershipPlans;
