import React from 'react';
import Slider from 'react-slick';
import css from './testimonial.module.scss';
import Quoteicon from '../../assets/quote.png';
import JillImg from '../../assets/Jill_S.jpg';
import MariaLImg from '../../assets/MariaL.jpg';
import AutImg from '../../assets/pfimgt.jpg';
import benifitsImg from '../../assets/benifits.png';
import NamedLink from '../NamedLink/NamedLink';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

function Testimonial(props) {
  const { location, currentUser, history } = props;
  const isProvider = currentUser?.attributes?.profile?.publicData?.isProvider;
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,

    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    // ],
  };
  return (
    <div className={`${css.testslider} testslider`}>
      {location.pathname === '/listmybusiness' ? (
        <>
          <div className={css.tssliderrow}>
            <div className={css.tsslidercol}>
              <img src={Quoteicon} />
              <p>
                Join Ireland’s leading platform for children’s activities and grow your activity
                business. Classes Camps Birthdays School Tours Workshops Events Clubs Breakfast
                Clubs Places to Visit After School Clubs In-School Programmes I would advise any
                other activity provider that I know to look at Activity time. Try it and if you
                don’t like it you can stop anytime, but if you do like it, make some money and take
                some marketing worries away. It’s that simple.
              </p>
              <h2>Emeka - Modern Legacy Academy, Co. Wicklow</h2>
            </div>
            <div className={css.tsslidercolimg}>
              <img src={AutImg} alt="" />
            </div>
          </div>
          <div className={classNames(css.tssliderrow, css.activityBenifits)}>
            <img src={benifitsImg} alt="" />
            <div className={css.benifitContent}>
              <h3>Benefits for you & local communities</h3>
              <div>
                <p>
                  Holiday makers with kids from Ireland and abroad who find, share and book your
                  activities will often spend money in nearby towns in local businesses, hotels and
                  more. We see this is a great way to not only prove your profitability but also
                  your local community.
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Slider {...settings}>
            <div className={css.tssliderrow}>
              <div className={css.tsslidercol}>
                <img src={Quoteicon} />
                <p>
                  I visit friends and family in Ireland every summer and love that it will be easy
                  to book some activities for my kids before we leave Spain.
                </p>
                <h2>- Maria L - Barcelona</h2>
              </div>
              <div className={css.tsslidercolimg}>
                <img src={MariaLImg} />
              </div>
            </div>

            <div className={css.tssliderrow}>
              <div className={css.tsslidercol}>
                <img src={Quoteicon} />
                <p>
                  This platform is going to save me SO much time and give me greater choice! Thank
                  you Activity Time - a much needed resource for parents!
                </p>
                <h2>- Jill S - Co. Wicklow</h2>
              </div>
              <div className={css.tsslidercolimg}>
                <img src={JillImg} />
              </div>
            </div>
          </Slider>

          <div className={css.tstgrpbtm}>
            <button
              onClick={() =>
                history.push(
                  createResourceLocatorString('SearchPage', routeConfiguration(), {}, {})
                )
              }
              className={css.srchButton}
            >
              Browse Activities
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Testimonial;
